.productPageLists {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center horizontally */
    text-align: center; /* Center text inside the container */
}

.productPage {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center horizontally */
    text-align: center; /* Center text inside the container */
}

.productPage h1 {
    font-family: "Libre Baskerville", serif;
    font-weight: 800;
    font-style: normal;
    font-size: 3rem;
}

.banner {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    margin-bottom: 5%;
}

.productPageLists ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    display: flex; /* Enable flexbox layout for the list */
    flex-direction: row; /* Stack list items horizontally by default */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: center; /* Center items horizontally */
}

.productPageLists ul li {
    flex: 1 1 45%; /* Allow items to take up 45% of the container width */
    margin: 5px; /* Add some margin between items */
}

@media (max-width: 800px) {
    .productPageLists ul li {
        flex: 1 1 45%; /* Two items per row on mobile */
    }

    body {
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    .banner {
        width: 100%;
        max-height: 200px;
        margin-bottom: 5%;
    }
}