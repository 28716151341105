.HomeBase{
    height: 100%;
}

.firstImage{
    max-height: 700px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.secondImage{
    width: 100%;
    height: auto;
    margin-top: 20px;
    max-height: 500px
    

}

@media (max-width: 800px) {


    
}