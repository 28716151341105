.shopTheDrop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
}

.shopTheDrop h1{
    border: 4px solid #000;
    padding: 10px;
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.shopTheDrop img {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}