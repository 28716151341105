footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around; /* Distribute space evenly */
    
}

footer ul li {
    flex: 1; /* Make each li take up equal space */
    cursor: pointer;
    padding: 10px;
    text-align: center;
    font-size: 1.5rem;
}

footer ul li:hover {
    background-color: #333; /* Change the background color on hover */
    color: #fff; /* Change the text color on hover */
}


@media (max-width: 800px) {

}