nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

nav ul li {
    margin-right: 20px;
}


nav img{
    margin-left: auto;
    margin-right: auto;

    max-width: 300px;
    object-fit: cover;
    object-position: center;
    margin-top: 1%;
}


@media (max-width: 800px) {
    nav img{
        max-width: 200px;
        margin-bottom: 2%;
    }
}