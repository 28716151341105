.pageNav {
    background-color: #ffffff; /* Background color for the nav bar */
    padding: 10px 0; /* Padding for the nav bar */
    width: 100%; /* Full width */
}

.pageNav ul {
    list-style-type: none; /* Remove default list styling */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: center; /* Center the items horizontally */
}

.pageNav li {
    margin: 0 15px; /* Space between each nav item */
}

.pageNav a {
    color: rgb(0, 0, 0); /* Text color */
    text-decoration: none; /* Remove underline from links */
    padding: 10px 15px; /* Padding for clickable area */
    display: block; /* Ensure the clickable area is block-level */
    font-size: 2rem;
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;

}

.pageNav a:hover {
    background-color: #f3f3f3; /* Background color on hover */
    border-radius: 4px; /* Optional: Add rounded corners on hover */
}

@media (max-width: 800px) {
    .pageNav ul {

        flex-direction: column; /* Stack items vertically */
        justify-content: center; /* Center the items vertically */
        align-items: center; /* Center the items horizontally */
    }

    .pageNav {
        border: none; /* Optional: Remove the border */
        width: 90%; /* Full width */
        padding: 0px 0; /* Padding for the nav bar */
    }

    .pageNav a {

        font-size: 1.5rem;

    
    }

    .pageNav li {
        width: 40%; /* Full width */
        margin: 1px 0; /* Space between each nav item */
        border: 2px solid #000; /* Optional: Add a border */
        padding: 0px; /* Adjust padding to decrease gap between border and text */
    }
}