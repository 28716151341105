.actualproduct {
    display: block; /* Ensure the link behaves like a block element */
    border: 2px solid #000000; /* Add a border around the content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
    text-align: center; /* Center the text */
    width: 25%; /* Optional: Set a maximum width */
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Inherit text color */
}

.actualproduct img {
    width: 100%; /* Ensure the image fits within the box */
    height: auto; /* Maintain the aspect ratio of the image */
    border-bottom: 4px solid black; /* Add a black border at the bottom of the image */
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
}

.actualproduct h2 {
    margin: 0; /* Remove default margin */
    padding: 8px; /* Add some padding around the text */
    font-family: "Libre Baskerville", serif;
    font-weight: 800;
    font-style: normal;
    color: seagreen;
}

.soldOut img {
    filter: grayscale(50%) !important; /* Apply a grey overlay to the image */
    opacity: 0.5 !important; /* Optional: Reduce the opacity */
    /* Remove width: 10% if not intended */
}

.soldOutText {
    color: red !important; /* Use !important to ensure the style is applied */
}

@media (max-width: 800px) {
    .actualproduct {
        display: block; /* Ensure the link behaves like a block element */
        border: 2px solid #000000; /* Add a border around the content */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
        text-align: center; /* Center the text */
        width: 100%; /* Optional: Set a maximum width */
        text-decoration: none; /* Remove underline from link */
        color: inherit; /* Inherit text color */
        margin: 0; /* Remove any default margin */
        padding: 0; /* Remove any default padding */
    }

    .actualproduct h2 {
        font-size: 1rem;
    }

    .actualproduct img {
        width: 100%; /* Ensure the image fits within the box */
        height: auto; /* Maintain the aspect ratio of the image */
        border-bottom: 4px solid black; /* Add a black border at the bottom of the image */
        border-top: none; /* Ensure no border at the top */
        margin: 0; /* Remove any default margin */
        padding: 0; /* Remove any default padding */
    }
}