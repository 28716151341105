.welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; /* Full viewport height */
    text-align: center; /* Center text within each half */
}

.welcome-text, .welcome-image {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.welcome-image img {
    max-width: 100%;
    height: auto;
}

.clear-both {
    clear: both;
}