.goodPurchaseBase {

    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */

}
.goodPurchaseHeader{
    /* margin-top: 10%;
    margin-bottom: 20px;
    background-color: #61a840;
    padding-left: 5%;
    padding-right: 5%; */
    /* padding-bottom: 10%; */
    text-align: center;
    padding-bottom: 20%;
    margin-top: 10%;


}
.goodPurchaseHeader, img{
    width: 50%;
    height: 50%;
    object-fit: cover;
    object-position: center;
}


.goodPurchaseHeader p{
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.goodPurchaseHeader h1{
    font-size: 2.5rem;
    color: #a84040;
    text-align: center;
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0;
}



@media (max-width: 800px) {
    .goodPurchaseHeader, img{
        width: 100%;
        margin-top: 5%;

    }

    .goodPurchaseHeader h1{
        font-size: 2rem;
        color: #a84040;
        text-align: center;
        font-family: "Archivo Black", sans-serif;
        font-weight: 400;
        font-style: normal;
    }

    .goodPurchaseBase{
        width: 75%;
        margin-left: 12.5%;
    }
    
}