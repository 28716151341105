.imageTextContainer {
    display: flex;
    flex-direction: column; /* Stack the image and text vertically */
    width: 100%;
    height: 100%;
    max-height: 650px; /* Optional: Set a max height */
    box-sizing: border-box;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap; /* Allow images to wrap if necessary */
}

.textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
}

.responsiveImage {
    max-width: 30%; /* Keep the images the same size */
    max-height: 450px;
    height: auto;
    width: auto;
    border: 5px solid #333; /* Optional: Add a border around the image */
    margin: 0.5%; /* Adjust margin for spacing */
}

.textContainer p {
    color: #333; /* Set the text color */
    text-align: center; /* Center the text */
    margin: 0; /* Remove default margin */
    border: 3px solid #333; /* Optional: Add a border around the text */
    padding: 1%;
}

/* Media query for mobile devices */
@media (max-width: 800px) {
    .imageTextContainer {
        height: auto; /* Adjust height for mobile view */
        font-size: 1rem;
    }

    .imageContainer, .textContainer {
        width: 100%; /* Each takes full width */
        padding: 10px 0; /* Adjust padding for mobile view */
    }

    .responsiveImage {
        width: 80%; /* Adjust image width for mobile view */
    }

    .textContainer{
        font-size: 1.5rem;
    }
}