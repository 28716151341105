/* Base styling for the product detail page */
.product-detail-base {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Arial, sans-serif';
    color: #333;
}

.product-detail-base h1 {
    font-family: "Libre Baskerville", serif;
    font-weight: 800;
    font-style: normal;
    padding-bottom: 10%;
}

/* Styling for the image and description section */
.product-image-description {
    display: flex;
    align-items: center; /* Center items vertically */
    gap: 20px; /* Space between the image viewer and the description */
    margin: 0 auto;
    padding: 20px;
    padding-right: 10%;
    background-color: #ffffff;
}

.price {
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: darkgreen;
}

.product-image-description .product-image-viewer {
    flex: 3; /* Make the image viewer 3 times the size of the description */
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between elements */
    padding: 0; /* Remove padding */
}

.product-image-description .description {
    flex: 1; /* Make the description take up the remaining space */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center text vertically */
    align-items: flex-start; /* Align text to the left */
    text-align: left; /* Align text to the left */
    gap: 15px; /* Space between elements */
    width: 100%; /* Ensure the description takes up full width */
    max-width: 100%; /* Allow the description to be as wide as needed */
}

.product-image-viewer {
    width: 100%; /* Ensure the image viewer takes up full width */
}

.product-image-description {
    margin: 0 0 10px 0;
    font-size: 24px;
    color: #000000;
}

.description h2 {
    font-size: 24px;
}

.product-image-description .description p {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    min-width: 500px;
}

.product-image-description .description .add-to-cart, .checkoutButton {
    width: 100%;
    padding: 10px 20px; /* Add padding inside the button */
    font-size: 2rem; /* Set the font size of the button text */
    background-color: #b91c1c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 2%;
    margin-top: 2%;
    transition: background-color 0.3s ease;
}

.product-image-description .description .add-to-cart:hover {
    background-color: #a51717;
}



/* Styling for the product image viewer */
.image-viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%; /* Ensure the image viewer takes up full width */
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.product-image {
    max-width: 100%;
    height: auto;
    transition: opacity 0.5s ease-in-out; /* Smooth transition for image change */
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-51%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
}

.nav-button.prev {
    left: 10px;
}

.nav-button.next {
    right: 10px;
}

.productSideBySide {
    display: flex;
    justify-content: space-between;
}

.productSideBySide > * {
    flex: 1; /* Optional: Makes each child take up equal space */
    margin: 0 10px; /* Optional: Adds some margin between the children */
}

/* Styling for the "Why K8" section */
.productwhyk8 {
    display: flex;
    flex: 1;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(19, 122, 11, 0.688);
    backdrop-filter: blur(10px);
    padding: 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 1.5rem;
}

.productwhyk8 h2 {
    margin-bottom: 10px;
    font-family: "Libre Baskerville", serif;
    font-weight: 800;
    font-style: normal;
}

.how-to-care h2 {
    margin-bottom: 10px;
    font-family: "Libre Baskerville", serif;
    font-weight: 800;
    font-style: normal;
}

.productwhyk8 p {
    font-size: 1.5rem;
    line-height: 1.5;
    color: #333;
}

.how-to-care {
    display: flex;
    flex: 1;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(19, 122, 11, 0.688);
    backdrop-filter: blur(10px);
    padding: 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 1.5rem;
}

.how-to-care ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.how-to-care li {
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5rem;
}

.shipping-option {
    display: flex;
    align-items: center; /* Center the input vertically */
    padding-bottom: 10%;
}

.shipping-option label {
    display: flex;
    align-items: center; /* Center the input and text vertically */
    font-size: 2rem; /* Increase the text size */
    color: #3e3900; /* Match the default text color */
    font-weight: normal; /* Match the default text weight */
}

.shipping-option input {
    margin-right: 10px; /* Add some space between the input and the label text */
    width: 20px; /* Set the width of the input field */
    height: 20px; /* Set the height of the input field */
}

.product-image-viewer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the image horizontally */
    gap: 10px; /* Space between the image and the thumbnails */
}

.product-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image */
}

.thumbnail-strip {
    display: flex;
    justify-content: space-between; /* Distribute thumbnails evenly */
    width: 50%; /* Make the thumbnail strip take the full width of the product image */
    gap: 10px; /* Space between thumbnails */
}

.thumbnail {
    flex: 1; /* Make each thumbnail take up equal space */
    max-width: 50%; /* Ensure each thumbnail is no more than 50% of the product image width */
    height: auto;
    cursor: pointer;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 3%;
}

.thumbnail.active {
    border: 2px solid #000; /* Highlight the active thumbnail */
}

@media (max-width: 800px) {
    .product-image-description {
        flex-direction: column; /* Stack items vertically */
        padding: 10px; /* Reduce padding */

    }

    .product-image-description .product-image-viewer,
    .product-image-description .description {
        flex: none; /* Remove flex-grow */
        width: 100%; /* Ensure full width */
        max-width: 100%; /* Ensure full width */
    }

    .product-image-description .description p {
        min-width: auto; /* Allow text to wrap */
        font-size: 1.2rem; /* Adjust font size */
    }

    .product-image-description .description .add-to-cart {
        width: 100%; /* Full width button */
        font-size: 1.5rem; /* Adjust font size */
        margin-left: 0; /* Remove left margin */
        margin-top: 10px; /* Add top margin */
    }

    .productwhyk8,
    .how-to-care {
        width: 90%; /* Full width */
        margin-left: 3%;
        font-size: 1.2rem; /* Adjust font size */
        margin-bottom: 5%;
    }

    .shipping-option label {
        font-size: 1.5rem; /* Adjust font size */
    }

    .product-detail-base h1 {
        font-size: 2rem; /* Adjust font size */
        padding-bottom: 0%;
    }

    .productSideBySide {
        display: flex;
        flex-direction: column;
    }
    

    
}