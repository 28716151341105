.aboutBase {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.aboutContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;

}

.aboutImage {
    flex: 1;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutImage img {
    width: 60%;
    height: auto;
    display: block;
}

.aboutText {
    flex: 1;
    max-width: 50%;
    padding: 20px;
    text-align: center; /* Center the text */
}

.aboutBase h1 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    text-align: center; /* Center the text */
}

.aboutBase p{
    font-size: 1.5rem;
    font-family: 'Arial, sans-serif';
}

/* on mobile stack vertically */

@media (max-width: 800px) {
    .aboutContent {
        flex-direction: column;
    }

    .aboutImage img {
        width: 100%;
    }


    .aboutBase p{
        font-size: 1rem;
    }

    .aboutText{
        max-width: 80%;
    }

    .aboutImage {
        padding-top: 5%;
    }
    
}